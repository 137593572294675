<template>
  <v-container fluid class="thankforpay-page pa-0">
    <v-row justify="center" align="center" class="main" no-gutters>
      <v-col cols="12" class="text-center">
        <img class="logo" src="/Image/logo_intro.png" alt="">
      </v-col>
      <v-col cols="12" class="text-center">
        <div class="card-box">
            {{$i18n.locale == 'th' ? 'ขอบคุณสำหรับการชำระเงิน': 'Thank you for ordering'}}
        </div>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn @click="$router.push('/')" dark>
            {{$i18n.locale == 'th' ? 'กลับหน้าแรก': 'Go to home'}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "thankPage",
    mounted() {
      
    }
  }
</script>

<style lang="scss">
  .thankforpay-page {
    height: 100vh;
    background-image: url('/Image/BG.png');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    .main {
      height: 100vh;
    }

    .card-box {
        border-radius: 12px;
        background: white;
        padding: 20px;
        color: black;
        max-width: 300px;
        margin: 0 auto;
        font-size: 19px;
        font-weight: 600;
        background: url('/Image/BG_4.png');
        background-size: cover;
    }

    .logo {
      width: 40%;
      height: auto;
      max-width: 250px;
    }
  }
</style>